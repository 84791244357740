<template>
  <div>
    <v-app-bar
        color="deep-purple accent-4"
        :fixed="true"
        dense
        dark>
      <div style="margin-right: 15px;" @click="back">
        <v-icon>mdi-chevron-left</v-icon>
      </div>
      <v-toolbar-title>TMS Order Task</v-toolbar-title>
    </v-app-bar>

    <div
        style="height: calc(100% - 50px - 56px); overflow-y: auto; margin-top: 48px; margin-bottom: 56px; width: 100%;"
    >
      <v-tabs style="width: 100%;"
              grow
              v-model="tab_select">
        <v-tab>All</v-tab>
        <v-tab>New</v-tab>
        <v-tab>Driving</v-tab>
        <v-tab>Arrived</v-tab>
        <v-tab>Waiting (optional)</v-tab>
        <v-tab>Loading or unloading</v-tab>
        <v-tab>Problem (optional)</v-tab>
        <v-tab>Complete</v-tab>
      </v-tabs>

      <v-list two-line>
        <v-list-item-group
            multiple
        >
          <template v-for="(item, index) in items">
            <v-list-item :key="item.id + 'v-list-item'" @click="goTmsOrderTaskDetail(item.tms_order_id, item.id)">
              <template v-slot:default>
                <v-list-item-action class="li-left-box">
                  <v-list-item-action-text v-text="'#' + item.order_in_tms_route"></v-list-item-action-text>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                      v-text="'Order Number: ' + tms_orders[item.tms_order_id]['tms_order_detail']['order_number'] + ' | '+item.task_type_str + ' | [' + item.status_str + ']'"></v-list-item-title>
                  <v-list-item-title v-text="'Task Company Name: ' + item.name"></v-list-item-title>
                  <v-list-item-title
                      v-text="'Total Collo: ' + item.coTotal + ' | Total Weight: ' + item.weightTotal"></v-list-item-title>
                  <v-list-item-subtitle class="text--primary" v-text="strOrderDisplay(item)"></v-list-item-subtitle>
                  <v-list-item-subtitle v-text="strOrderDisplay2(item)"></v-list-item-subtitle>
                  <v-list-item-subtitle v-text="'Instruction: ' + item.instruction"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>

            <v-divider
                v-if="index + 1 < items.length"
                :key="item.id + 'v-divider'"
            ></v-divider>
          </template>

          <v-list-item :key="'next_item_btn_3'" v-if="show_no_data">
            <template v-slot:default>
              <v-list-item-content>
                <v-list-item-title style="color: #1867c0;" v-text="'No Data'"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>

          <template v-if="items.length > 0">
            <v-divider
                :key="'v-divider-next_item_btn_top'"
            ></v-divider>

            <v-list-item :key="'next_item_btn_2'">
              <template v-slot:default>
                <v-list-item-content>
                  <v-list-item-title style="color: #1867c0;" v-text="'End'"></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </template>

        </v-list-item-group>
      </v-list>
    </div>

    <v-snackbar
        v-model="snackbar"
        :timeout="snackbar_timeout"
    >
      {{ snackbar_txt }}
      <v-btn
          style="display: none;"
          color="pink"
          text
          @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import {get} from '@/utils/request.js'
import {EH_MOBILE_GLOBAL} from '@/helper.js'
import global_ from '@/utils/Global'

export default {
  data() {
    return {
      snackbar: false,
      snackbar_txt: '',
      snackbar_timeout: 1500,
      null_val: null,

      show_no_data: false,
      tab_id_to_status: {
        0: 'all',
        1: 'new',
        2: 'driving',
        3: 'arrived',
        4: 'waiting (optional)',
        5: 'loading or unloading',
        6: 'problem (optional)',
        7: 'complete'
      },

      // page: 1,
      tms_route_id: null,
      has_next_page: true,
      items: [],
      tms_orders: {},
      tab_select: 0
    }
  },
  watch: {
    tab_select(newval, oldval) {
      this.null_val = newval
      this.null_val = oldval
      this.items = []
      this.getTmsOrderList()

      global_.current_tms_task_table = newval
    },
  },
  methods: {
    getTmsOrderList: function () {
      let url = EH_MOBILE_GLOBAL.get_tms_order_list + '?tms_route_id=' + this.tms_route_id + '&task_status=' + this.tab_id_to_status[this.tab_select]
      get(url, null, {}).then((response) => {
        let data = response.data
        if (data.status != 'success') {
          this.snackbar_txt = data.reason
          this.snackbar = true
          return
        }

        this.items = data.tasks
        this.tms_orders = data.orders
        if (this.items.length <= 0) {
          this.show_no_data = true
        } else {
          this.show_no_data = false
        }
      })
    },
    strOrderDisplay: function (info) {
      return info['street'] + ' ' + info['number'] + ', ' + info['postal_code'] + ', ' + info['place'] + ', ' + info['country_code']
    },
    strOrderDisplay2: function (info) {
      return info['date_time_begin'].substr(0, info['date_time_begin'].length - 3) + ' - ' +
          info['date_time_end'].substr(0, info['date_time_end'].length - 3) //info['date_time_end']
    },
    goTmsOrderDetail: function (tms_order_id) {
      let path = '/tms/order/detail/' + this.tms_route_id + '/' + tms_order_id
      // console.log(path)
      this.$router.push({
        path: path
      })
    },
    goTmsOrderTaskDetail: function (tms_order_id, tms_order_task_id) {
      let path = '/tms/order/task/detail/' + this.tms_route_id + '/' + tms_order_id + '/' + tms_order_task_id
      this.$router.push({
        path: path
      })
    },
    back: function () {
      this.$router.go(-1)
    }
  },
  created() {
    if (global_.current_tms_task_table > 0) {
      this.tab_select = global_.current_tms_task_table
    }
    this.tms_route_id = this.$route.params.tms_route_id
    this.getTmsOrderList()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div >>> .v-slide-group:not(.v-slide-group--has-affixes) > .v-slide-group__prev {
  display: none !important;
}

.v-application--is-ltr .li-left-box {
  margin-right: 5px;
}

div >>> .v-list-item.v-list-item--link.theme--light {
  padding-right: 8px;
  padding-left: 8px;
}
</style>
